const { z, HumanisedBooleanLike } = require('@reckon-web/next-config/client');

/**
 * @type {import('./__generated__/config').ConfigEnv}
 */
module.exports = {
  APPLICATION_LOCALSTORAGE_PREFIX: z.string().default('launch_'),
  APPLICATION_DISPLAY_NAME: z.string().default('Reckon Launch'),
  APPLICATION_INTERCOM_APPNAME: z.string().default('LAUNCH_WEB'),
  APPLICATION_HELP_AND_SUPPORT_URL: z
    .string()
    .default('https://help.reckon.com/'),
  APPLICATION_SENTRY_DSN: z
    .string()
    .default(
      'https://456a95fff14d6e5a7db6cb05afbbf2b6@o378482.ingest.sentry.io/4505949203136512'
    ),

  APPLICATION_RECKON_APP_LAUNCHER_URL: z
    .string()
    .default('https://apps.dev.reckoncloud.com.au'),
  APPLICATION_DEADLY_DIGITS_APP_LAUNCHER_URL: z
    .string()
    .default('https://apps.dev.reckoncloud.com.au'),
  APPLICATION_APOLLO_CLIENT_API_URL: z
    .string()
    .default('https://graphql.dev.reckonapi.com/graphql'),
  APPLICATION_RECKON_PORTAL_URL: z
    .string()
    .default('https://genesis.dev.reckoncloud.com.au/'),
  APPLICATION_DEADLYDIGITS_PORTAL_URL: z
    .string()
    .default('https://deadlydigits-genesis.dev.reckoncloud.com.au/'),

  APPLICATION_FEATURE_USE_DEVLAUNCHER: HumanisedBooleanLike.default(true),
};
